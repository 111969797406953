<template>
  <transition appear>
    <div class="cookie cookie-notice card" :class="[containerPosition, cookieTheme]" v-if="isOpen">
      <div class="card-body">
        <slot name="message"><p>{{ message }}</p></slot>
        <div class="text-center">
          <div class="btn-group">
            <a v-if="informationButtonLink" class="btn btn-sm btn-outline-primary" :href="informationButtonLink">{{ informationButtonText }}</a>
            <button class="btn btn-sm btn-primary" @click="accept">{{ closeButtonText }}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { Cookie } from 'js-cookie'
export default {
  props: {
    closeButtonText: {
      type: String,
      default: 'Ok'
    },
    informationButtonLink: {
      type: [String, Object],
      required: false
    },
    informationButtonText: {
      type: String,
      default: 'Datenschutzerklärung'
    },
    message: {
      type: String,
      default: 'This website uses cookies to ensure you get the best experience on our website.'
    },
    theme: {
      type: String,
      default: 'base'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    transitionName: {
      type: String,
      default: 'slideFromBottom'
    },
    closeButtonVariant: {
      type: String,
      default: 'primary'
    },
    informationButtonVariant: {
      type: String,
      default: 'outline-primary'
    }
  },
  emits: ['accept'],
  setup (props, { emit }) {
    const supportsLocalStorage = ref(true)
    const isOpen = ref(false)
    // const buttonLink = ref(null)
    // const buttonLinkNewTab = ref(null)
    // computed
    const containerPosition = computed(() => {
      return `cookie-${props.position}`
    })
    const cookieTheme = computed(() => {
      return `cookie-${props.theme}`
    })
    /*
    const externalButtonLink = computed(() => {
      return typeof buttonLink.value === 'string' && buttonLink.value.length
    })
    const intenalButtonLink = computed(() => {
      return typeof buttonLink.value === 'object' && buttonLink.value !== null && Object.keys(buttonLink.value).length
    })
    const target = computed(() => {
      return buttonLinkNewTab ? '_blank' : '_self'
    })
    */
    // methods
    function setVisited () {
      if (supportsLocalStorage.value) {
        localStorage.setItem('cookie:accepted', true)
      } else {
        Cookie.set('cookie:accepted', true)
      }
    }
    function getVisited () {
      if (supportsLocalStorage.value) {
        return localStorage.getItem('cookie:accepted')
      } else {
        return Cookie.get('cookie:accepted')
      }
    }
    function accept () {
      setVisited()
      isOpen.value = false
      emit('accept')
    }
    onMounted(() => {
      try {
        const test = '__vue-cookielaw-check-localStorage'
        window.localStorage.setItem(test, test)
        window.localStorage.removeItem(test)
      } catch (e) {
        console.error('Local storage is not supported, falling back to cookie use')
      }
      if (!getVisited() === true) {
        isOpen.value = true
      }
    })
    return {
      containerPosition,
      cookieTheme,

      isOpen,
      accept
    }
  }
}
</script>
<style lang="scss" scoped>
.cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}
</style>
