<template>
<div class="wrapper">
  <Header />
  <child />
  <Footer />
  <CookieLaw information-button-link="https://www.loewe-zaun.de/datenschutz/" close-button-variant="success" theme="gray-transparent">
    <template v-slot:message>
      <p>Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen zu Cookies erhalten Sie in unserer</p>
    </template>
  </CookieLaw>
</div>
</template>
<script>
import Header from './partials/header'
import Footer from './partials/footer'
import CookieLaw from '~/components/CookieLaw'
export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer,
    CookieLaw
  }
}
</script>
