export default class {
  constructor (configurator) {
    this.configurator = configurator
  }

  process () {
    this.updateSelectedLabels()

    return this
  }

  updateSelectedLabels () {
    this.configurator.steps.forEach((step) => {
      if (step.width && step.height) {
        step.selectedLabel = step.width.value + step.width.unit + ' x ' + step.height.value + step.height.unit

        return
      }

      if (step.type === 'quantity') {
        step.selectedLabel = step.aw + 'm Zaun'
        return
      }

      if (step.type === 'dimension_table') {
        const option = step.options.find(o => o.selected)
        step.selectedLabel = option.width + step.unit + ' x ' + option.height + step.unit

        return
      }

      if (step.options) {
        const selectedOptionNames = step.options
          .filter((option) => option.selected && !option.disabled)
          .map(o => o.type === 'count' ? o.value + 'x ' + o.name : o.name)

        if (selectedOptionNames.length > 0) {
          step.selectedLabel = selectedOptionNames.join(', ')

          return
        }
      }

      // only for color step
      if (step.colors) {
        const color = step.colors.find((color) => color.selected)
        step.selectedLabel = color.label || color.name

        return
      }

      step.selectedLabel = '--'
    })
  }
}
