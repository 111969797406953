import BaseClass from './system/base'
import MainClass from './system/main'
import SubfClass from './system/subf'
import SubtClass from './system/subt'
import SubtrClass from './system/subtr'
import Serializer from './serializerSystem'

const System = class {
  constructor (system) {
    this.clone = false
    this.id = system.id
    this.config_id = system.config_id
    this.add_mailbox = system.add_mailbox
    this.add_to_base = system.add_to_base
    this.add_to_config = system.add_to_config
    this.base = new BaseClass(system.base)
    this.cachedSummary = system.cachedSummary
    this.config_base = system.config_base
    this.config_main = system.config_main
    this.config_sub_1 = system.config_sub_1
    this.config_sub_2 = system.config_sub_2
    this.config_sub_3 = system.config_sub_3
    this.dimension = system.dimension
    this.do_not_add = system.do_not_add
    this.mailbox = system.mailbox
    this.main = new MainClass(system.main)
    this.options = system.options
    this.products = system.products
    this.sub_1 = new SubfClass(system.sub_1)
    this.sub_2 = new SubtClass(system.sub_2)
    this.sub_3 = new SubtrClass(system.sub_3)
    this.availableWidth = null
    this.builderSteps = null
    this.montage = 0
  }

  /**
   * @description initialize start options
  */
  initializeSystem () {
    // set default value to options
    this.options[0].selected = true
    this.updatePfosten()

    const fields = ['base', 'main', 'sub_1', 'sub_2']
    fields.forEach(field => {
      const step = this[field].steps.find(s => s.name === 'Montage')
      const option = step.options.find(o => o.selected)
      if (option && option.selected) {
        option.selected = false
        step.selectedLabel = '--'
      }
    })
    this.setMontageCost()
  }

  setMontageCost () {
    this.calculateAmountProducts()
    const fields = ['base', 'main', 'sub_1', 'sub_2']
    let montageTotal = 0
    fields.forEach(field => {
      const config = 'config_' + field
      if (this[config].show) {
        const montage = this[config].montage
        const width = this[field].steps.find(s => s.type === 'dimension').width.value / 100
        if (field === 'base') {
          montageTotal += (width * this[field].quantity) * montage
        } else {
          montageTotal += montage
        }
      }
    })
    this.montage = montageTotal
  }

  /**
   * @description update Products
   */
  setProducts () {
    this.calculateAmountProducts()
  }

  /**
   *
   * @param {*} data
   */
  setByConfig (data) {
    this.products.push(data)
  }

  /**
   *
   * @param {*} data
   */
  setConfigShow (data) {
    if (typeof data !== 'undefined') {
      const config = 'config_' + data.config
      const checked = (Number.parseInt(data.checked) === 1)

      this[config].show = checked

      this.updatePfosten()
    }
  }

  /**
   *
   * @param {*} data
   */
  setConfigOptionsShow (data) {
    if (typeof data !== 'undefined') {
      const optionId = data.id
      const checked = (Number.parseInt(data.checked) === 1)
      // const column = (Number.parseInt(data.c) === 1)
      const o = this.options.find(o => o.id === optionId)
      o.selected = checked
      /*
      if (column) {
        // if client column
        const checked = false
      } else {
        const checked = true
      }
      */
      this.updatePfosten()
    }
  }

  /**
   * @description update Column
   */
  updatePfosten () {
    this.calculateAmountProducts()
    const fields = ['base', 'main', 'sub_1', 'sub_2']

    fields.forEach(field => {
      const config = 'config_' + field
      const step = this[field].steps.find(s => s.id === this[config].step_p)
      const option = step.options.find(o => o.id === this[config].step_po)
      const oldSelected = step.options.find(o => o.selected)

      if (!this.options[0].selected) {
        const defaultOption = step.options.filter(o => o.id)
        oldSelected.selected = false
        defaultOption[0].selected = true
        step.selectedLabel = defaultOption[0].name
      } else {
        oldSelected.selected = false
        option.selected = true
        // // set new name params
        if (field === 'base' && this.options[0].selected === true) {
          this.base.update()
          const newName = parseFloat((this.availableWidth / 2.50)).toFixed(0) + ' x Stahlpfosten (80 x 80 mm)'
          option.name = newName
        }
      }

      this[field].update()
    })
  }

  /**
   *
   * @param {*} config
   * @param {*} type
   * @param {*} value
   */
  setDimension (config, type, value) {
    // const step = this[config].steps.find(step => step.type === 'dimension')
    // const option = step[type].value = Number.parseInt(value)

    this.updatePfosten()
    // this[config].update();
    this.calculateAmountProducts()
  }

  /**
   *
   * @param {*} type
   * @param {*} value
   */
  setDimensionBase (type, value) {
    const step = this.base.steps.find(step => step.type === 'dimension')
    // set height
    if (type === 'height') {
      const height = Number.parseInt(value)
      const fields = ['main', 'sub_1', 'sub_2']
      fields.forEach(field => {
        const sp = this[field].steps.find(s => s.type === 'dimension')
        sp.height.value = height

        this[field].update()
      })
      this.dimension.height.value = height
      step.height.value = height
      // set width
    } else if (type === 'width') {
      this.dimension.width.value = Number.parseInt(value)
      this.updatePfosten()
    }

    this.calculateAmountProducts()
  }

  /**
    * @description set quantity for option
    * @param item - object
    **/
  setQuantity (item) {
    let count = 0
    const step = this.main.steps.find(s => s.id === this.config_main.steps[0])
    const option = step.options.find(o => o.id === item.id)
    if (option.value === 0) {
      option.selected = true
    }
    if (item.action === '+') {
      count = Math.min(option.value + 1, option.max)
      option.value = count
    } else if (item.action === '-') {
      count = Math.max(option.value - 1, option.min)
      option.value = count
      if (count === 0) {
        option.selected = false
      }
    }
    // step.labelSelected = step.labelSelected
    this.main.calculateTotalPrice({ step: step.id, option: item.id })
    this.main.update()
  }

  /**
    * @param item - object
    * @description - set quantity for flugeltore
    */
  setSubTwoQuantity (item) {
    let count = 0
    const step = this.sub_2.steps.find(s => s.id === this.config_sub_2.steps[0])
    const option = step.options.find(o => o.id === item.id)

    if (option.value === 0) {
      option.selected = true
    }
    if (item.action === '+') {
      count = Math.min(option.value + 1, option.max)
      option.value = count
    } else if (item.action === '-') {
      count = Math.max(option.value - 1, option.min)
      option.value = count
      if (count === 0) {
        option.selected = false
      }
    }
    // step.labelSelected = step.labelSelected
    this.sub_2.calculateTotalPrice({ step: step.id, option: item.id })
    this.sub_2.update()
  }

  /**
   *
   * @param {*} value
   */
  setMainOptions (value) {
    if (value.id) {
      const steps = this.config_main.steps
      // const checkedOption = {}
      steps.forEach(step => {
        const item = this.main.steps.find(s => s.id === step)
        if (value.attr === 'parent') {
          const parentIsChecked = item.options.filter(o => o.disabled === false) && item.options.find(o => o.selected)
          const nowIsChecked = item.options.find(o => o.id === value.id)
          // console.log(item.options.find(o => o.id === value.id)); срабатывает только на одно опшн, иначе ошибка, если добавятся опции, переписать функцию

          if (parentIsChecked.selected) {
            parentIsChecked.selected = false
            nowIsChecked.selected = value.checked
          } else {
            parentIsChecked.selected = true
            nowIsChecked.selected = false
          }
        } else if (value.attr === 'child') {
          // const childIsChecked = item.options.filter(o => o.disabled === true) && item.options.find(o => o.selected)
          const nowIsChecked = item.options.find(o => o.id === value.id)

          nowIsChecked.selected = value.checked
        }
        // get selected options
        // let oname = [],
        //    selectedOptionsAll = item.options.filter(o => o.selected === true);
        // selectedOptionsAll.forEach(opt => {
        //    oname.push(opt.name);
        // this.main.calculateTotalPrice({step: step, option: value.id});
        // this.main.update();
        this.main.update()
        // });
      })
    }
  }

  /**
   * @class this.sub_1
   * @param {*} value
   */
  setSubOneOption (value) {
    const step = this.sub_1.steps.find(s => s.id === value.attr)
    const option = step.options.find(o => o.id === value.id)

    if (option.type === 'check') {
      option.selected = value.checked
    } else {
      const oldOption = step.options.find(o => o.selected)
      if (oldOption && oldOption.selected) {
        oldOption.selected = false
        option.selected = true
      } else {
        option.selected = true
      }
    }
    this.sub_1.update()
  }

  /**
   * @class this.sub_2
   * @param {*} value
   */
  setSubTwoOption (value) {
    const step = this.sub_2.steps.find(s => s.id === value.attr)
    const option = step.options.find(o => o.id === value.id)

    if (option.type === 'check') {
      option.selected = value.checked
    } else {
      const oldOption = step.options.find(o => o.selected)
      if (oldOption && oldOption.selected) {
        oldOption.selected = false
        option.selected = true
      } else {
        option.selected = true
      }

      // this.sub_2.selectOption({id: value.attr}, {id: value.id});
    }
    this.sub_2.update()
  }

  /**
   * @class this.sub_3
   * @param {*} value
   */
  setSubThreeOption (value) {
    const step = this.sub_3.steps.find(s => s.id === value.attr)
    const option = step.options.find(o => o.id === value.id)

    if (value.name === 'children') {
      option.selected = true
    } else {
      step.options.filter(o => { o.selected = false })
      option.selected = true
    }
    const baseStep = this.base.steps.find(s => s.name === 'Briefkasten')
    // optionBase = baseStep.options.find(o => o.selected);

    this.sub_3.update()
    if (baseStep) {
      baseStep.selectedLabel = step.selectedLabel
      /* optionBase.selected = false;
      let newBaseOption = baseStep.options.find(o => o.name === option.name);
      if (newBaseOption) {
        newBaseOption.selected = true;
      }
      console.log(newBaseOption);
      console.log(optionBase); */
      // this.base.update();
    }
  }

  /**
   * @param {*} value
   * @description finding this.base step & option and change selected
   */
  setOptionsValue (value) {
    if (value.stepId) {
      const stepId = value.stepId
      const optionId = value.optionId
      // const selected = value.checked

      const step = this.base.steps.find(s => s.id === stepId)
      const oldSelected = step.options.find(o => o.selected)
      const nowSelected = step.options.find(o => o.id === optionId)
      if (typeof nowSelected.show_for === 'undefined') {
        oldSelected.selected = false
        nowSelected.selected = true
        step.options.filter(o => {
          if (o.selected && typeof o.show_for !== 'undefined') {
            o.selected = false
          }
        })
      } else {
        if (typeof nowSelected.show_for !== 'undefined') {
          step.options.filter(o => {
            if (o.selected && typeof o.show_for !== 'undefined') {
              o.selected = false
              nowSelected.selected = true
            }
          })
        }
        nowSelected.selected = true
      }
    }
  }

  /**
   *
   * @param {true/false} data
   */
  setMontageToBase (data) {
    const fields = ['base', 'main', 'sub_1', 'sub_2']
    fields.forEach(field => {
      const step = this[field].steps.find(s => s.name === 'Montage')
      if (data) {
        step.selectedLabel = 'Mit Komplettmontage'
      } else {
        step.selectedLabel = '--'
      }
    })
  }

  /**
   * @description preview image on the front page live preview
   */
  getPreviewImages () {
    const fields = ['main', 'base', 'sub_1', 'sub_2'] // without sub_2
    const array = []
    fields.forEach(field => {
      const imgs = this[field].getPreviewImages()
      array.push({ config: field, images: imgs })
    })
    return array
  }

  /**
   * @description magik function to set up navigation menu
   */
  getStepMenu () {
    const configFields = ['step_main', 'step_sub']
    const steps = []
    let i = 0
    configFields.forEach(configField => {
      const step = this.base.steps.find(step => step.id === this.config_base[configField])
      if (step.name === 'Form') {
        steps.push(
          {
            id: step.id,
            name: step.name,
            sort: 3,
            type: step.type
          }
        )
      } else {
        steps.push(
          {
            id: step.id,
            name: step.name,
            type: step.type,
            sort: i
          }
        )
      }

      i++
    })
    // set farbe & dimensilon steps
    const color = this.base.steps.find(step => step.type === 'color')
    steps.push(
      {
        id: color.id,
        name: color.name,
        sort: 4,
        type: color.type
      },
      {
        id: '3aav',
        name: 'Maße',
        type: 'dimension',
        sort: 2
      }
    )
    const fields = ['main', 'sub_1', 'sub_2', 'sub_3']
    fields.forEach(field => {
      const step = this[field]
      const config = 'config_' + field
      steps.push({
        id: step.id,
        name: step.name,
        type: 'steps_items',
        show: this[config].show,
        config: field
      })
    })
    // set options
    this.options.forEach(option => {
      steps.push({
        id: option.id,
        name: option.name,
        type: 'options',
        config: 'opt'
      })
    })
    steps.push({
      id: 'abfrsd33',
      name: 'Zusammenfassung',
      type: 'total',
      config: 'total'
    })

    return steps
  }

  /**
   * @description set steps
   */
  getSteps () {
    const configFields = ['step_main', 'step_sub']
    const steps = []
    configFields.forEach(configField => {
      const step = this.base.steps.find(step => step.id === this.config_base[configField])
      steps.push(step)
    })
    // set farbe & dimensilon steps
    const color = this.base.steps.find(step => step.type === 'color')
    steps.push(
      color,
      {
        id: 'asdjk-1s20sadj-sadsad-2131sd',
        name: 'Maße',
        title: 'Geben Sie jetzt die Gesamtbreite von Ihre Zaunanlage',
        visible: true,
        type: 'dimension',
        price: 0,
        height: this.dimension.height,
        width: this.dimension.width
      }
    )
    const fields = ['main', 'sub_1', 'sub_2', 'sub_3']
    fields.forEach(field => {
      const step = this[field]
      const config = 'config_' + field
      steps.push({
        id: step.id,
        name: step.name,
        visible: true,
        type: 'steps_items',
        price: step.price,
        options: step.steps,
        show: this[config].show,
        config: field
      })
    })
    // set options
    this.options.forEach(option => {
      steps.push({
        id: option.id,
        name: option.name,
        visible: true,
        type: 'options',
        options: null,
        show: option.selected
      })
    })
    steps.push({
      id: 'abfrsd33',
      name: 'Zusammenfassung',
      visible: true,
      type: 'total',
      options: null,
      config: 'total'
    })

    return steps
  }

  /**
   * @description get data from main config
   */
  getFromMainConfig () {
    const stepsId = this.config_main.steps
    const data = []
    stepsId.forEach(s => {
      data.push(this.main.steps.find(step => step.id === s))
    })
    return data
  }

  /**
   * @description get data from sub_1 config
   */
  getFromSub1Config () {
    const stepsId = this.config_sub_1.steps
    const data = []
    stepsId.forEach(s => {
      data.push(this.sub_1.steps.find(step => step.id === s))
    })
    return data
  }

  /**
   * @description get data from sub_2 config
   */
  getFromSub2Config () {
    const stepsId = this.config_sub_2.steps
    const data = []
    stepsId.forEach(s => {
      data.push(this.sub_2.steps.find(step => step.id === s))
    })
    return data
  }

  /**
   * @description get data from base config
   */
  getBaseSubSteps () {
    const steps = this.config_base.steps
    const data = []
    steps.forEach(step => {
      const item = this.base.steps.find(s => s.id === step)

      data.push(item)
    })
    return data
  }

  /**
   * @description get data from sub_3 config
   */
  getFromSub3Config () {
    const stepsId = this.config_sub_3.steps
    const data = []
    stepsId.forEach(s => {
      data.push(this.sub_3.steps.find(step => step.id === s))
    })
    return data
  }

  /**
   * @description calculation and return total price
   * @returns total
   */
  getTotalPrices () {
    const basePrice = Math.round(this.base.price.discounted)
    const mainPrice = (this.config_main.show === true) ? this.main.price.discounted : 0
    const sub1 = (this.config_sub_1.show === true) ? this.sub_1.price.discounted : 0
    const sub2 = (this.config_sub_2.show === true) ? this.sub_2.price.discounted : 0
    const sub3 = (this.config_sub_3.show === true) ? this.sub_3.price.discounted : 0

    const discountedPrice = Math.round(basePrice + mainPrice + sub1 + sub2 + sub3)

    const baseOldPrice = Math.round(this.base.price.old_price)
    const mainOldPrice = (this.config_main.show === true) ? this.main.price.old_price : 0
    const subOld1 = (this.config_sub_1.show === true) ? this.sub_1.price.old_price : 0
    const subOld2 = (this.config_sub_2.show === true) ? this.sub_2.price.old_price : 0
    const subOld3 = (this.config_sub_3.show === true) ? this.sub_3.price.old_price : 0

    const oldPrice = Math.round(baseOldPrice + mainOldPrice + subOld1 + subOld2 + subOld3)

    const data = {
      discounted: discountedPrice,
      price: oldPrice
    }

    return data
  }

  /**
   * @description I do not what is it function
   */
  getMailboxName () {
    const step = this.sub_3.steps.find(s => s.id === this.config_sub_3.step_main)
    const option = step.options.find(o => o.selected)
    return option.name
  }

  getMetersFromConfigs () {
    const fields = ['main', 'sub_1', 'sub_2']
    const data = []

    fields.forEach(field => {
      const dim = this[field].getDimensionStep().width.value
      const cm = dim / 100

      data.push({ name: field, value: cm })
    })
    return data
  }

  // set selected optins for all config
  selectOptionAll (option) {
    const fields = ['main', 'base', 'sub_1', 'sub_2']
    const configFields = ['step_main', 'step_sub']
    const serializer = new Serializer()

    configFields.forEach(configField => {
      let mainStepOptionName
      const step = this.base.steps.find(step => step.id === this.config_base[configField])
      if (step && (mainStepOptionName = step.options.find(o => o.id === option.id))) {
        fields.forEach(field => {
          const configStr = 'config_' + field
          const stepId = this[configStr][configField]
          const step = this[field].steps.find(s => s.id === stepId)
          const matchingOption = step.options.find(o => {
            return serializer.slugify(o.name) === serializer.slugify(mainStepOptionName.name)
          })
          // const selectedOption = step.options.find(o => o.selected)

          this[field].selectOption({ id: step.id }, { id: matchingOption.id })
          // this[field].calculateTotalPrice({step: step.id, option: matchingOption.id });
          // this[field].update();
        })
      }
    })
  }

  // set color for all selected options
  selectColorAll (option) {
    const fields = ['base', 'main', 'sub_1', 'sub_2']
    const serializer = new Serializer()

    fields.forEach(field => {
      const step = this[field].steps.find(step => step.type === 'color')
      const stepBase = this.base.steps.find(step => step.type === 'color') // get base step
      const baseStepOptionName = stepBase.colors.find(c => c.id === option.id)
      const matchingOption = step.colors.filter(c => {
        return serializer.slugify(c.name.replace(/ +/g, '')) === serializer.slugify(baseStepOptionName.name.replace(/ +/g, ''))
      })
      const selectedOption = step.colors.find(c => c.selected)
      if (matchingOption.length === 1 && selectedOption.id !== matchingOption[0].id) {
        this[field].selectColor({ id: step.id }, { id: matchingOption[0].id })
      }
    })
  }

  /**
   * @description calculation and set to @array products
   */
  calculateAmountProducts () {
    let availableWidth = this.dimension.width.value * this.dimension.width.factor
    const baseDim = this.base.getDimensionStep().width
    const addToConfig = !this.do_not_add ? this.add_to_config : 0
    const addToBase = !this.do_not_add ? this.add_to_base : 0
    const fields = ['main', 'sub_1', 'sub_2']

    let data = {}
    this.products = []
    fields.forEach(field => {
      const config = 'config_' + field
      if (this[config].show) {
        data = {
          config_id: this[field].id,
          amount: 1,
          config: field,
          // availableWidth: availableWidth / 100,
          dimension: this[field].getDimensionStep(),
          price: this[field].price,
          name: this[field].name,
          steps: this[field].getActiveSteps()
        }

        this.setByConfig(data)
        availableWidth -= this[field].getDimensionStep().width.value - addToConfig
        this.availableWidth = availableWidth / 100
      }
    })

    // remaining base
    if (baseDim.min <= availableWidth) {
      let timesBase; let rest
      let baseWidth = baseDim.max
      let minRest = availableWidth
      let minTimes = availableWidth
      let minWidth = baseWidth

      do {
        timesBase = Math.floor(availableWidth / (baseWidth + addToBase))
        rest = availableWidth - timesBase * (baseWidth + addToBase)

        if (rest <= minRest) {
          minRest = rest
          minTimes = timesBase
          minWidth = baseWidth
        }
        if (rest > 0) {
          baseWidth -= baseDim.step
        }
      } while (rest > 0 && baseWidth > baseDim.min)
      // (20m * 100) - 350 =
      /* let s = availableWidth / 100;
      let m = s.toFixed(2);
            console.log(this.round10(m, 1)); */
      this.base.getDimensionStep().width.value = minWidth
      this.availableWidth = availableWidth / 100

      const q = this.base.steps.find(step => step.type === 'quantity')
      q.value = minTimes
      q.aw = this.availableWidth
      data = {
        amount: 1,
        config: 'base',
        config_id: this.base.id,
        dimension: this.base.getDimensionStep(),
        availableWidth: this.availableWidth,
        price: this.base.price,
        name: this.base.name,
        steps: this.base.steps
      }

      this.setByConfig(data)
    }
    this.base.update()
  }

  /* round10 (num, dp) {
    var numToFixedDp = Number(num).toFixed(dp);
    return Number(numToFixedDp);
  } */

  /**
   * @description summary
   */
  summary () {
    const summary = {
      dimension: JSON.stringify(this.dimension),
      products: [],
      montage: this.montage
    }

    this.products.filter(product => {
      return product.amount > 0
    }).forEach(product => {
      // get images by config
      const img = []
      this[product.config].getPreviewImages().then(arr => {
        arr.forEach(a => {
          img.push(a)
        })
      })

      if (this.add_mailbox && this[product.config].config_id === this.base.config_id) {
        const quantityStep = this.base.steps.find(step => step.type === 'quantity')
        const step = this.base.steps.find(step => step.id === this.mailbox.step)
        const mailboxOption = step.options.find(option => option.id === this.mailbox.option)
        const selectedBeforeMailboxOption = step.options.find(option => option.selected)
        // select mailbox temporary and push to cart
        quantityStep.value = 1
        selectedBeforeMailboxOption.selected = false
        mailboxOption.selected = true
        step.selectedLabel = mailboxOption.name
        // if more then 1 of base push to cart
        if (quantityStep.value > 1) {
          quantityStep.value = quantityStep.value - 1
          summary.products.push({
            amount: 1,
            summary: {
              id: this[product.config].id,
              images: img,
              name: this[product.config].name,
              price: {
                discounted: this[product.config].price.discounted,
                total: this[product.config].price.old_price
              },
              steps: this[product.config].getActiveSteps().filter(s => s.visible).map(step => {
                return {
                  name: step.name,
                  printDescription: step.printDescription,
                  value: step.selectedLabel,
                  price: step.price.total
                }
              })
            }
          })
        }

        summary.products.push({
          amount: 1,
          summary: {
            id: this[product.config].id,
            images: img,
            name: this[product.config].name,
            price: {
              discounted: this[product.config].price.discounted,
              total: this[product.config].price.old_price
            },
            steps: this[product.config].getActiveSteps().filter(s => s.visible).map(step => {
              return {
                name: step.name,
                printDescription: step.printDescription,
                value: step.selectedLabel,
                price: step.price.total
              }
            })
          }
        })
      } else {
        summary.products.push({
          amount: product.amount,
          summary: {
            id: this[product.config].id,
            images: img,
            name: this[product.config].name,
            price: {
              discounted: this[product.config].price.discounted,
              total: this[product.config].price.old_price
            },
            steps: this[product.config].getActiveSteps().filter(s => s.visible).map(step => {
              if (product.config === 'base') {
                // console.log(this[product.config]);
                return {
                  name: step.name,
                  printDescription: step.printDescription,
                  value: (step.name === 'Maße') ? '-' : step.selectedLabel,
                  price: (step.name === 'Maße') ? '-' : step.price.total
                }
              } else {
                return {
                  name: step.name,
                  printDescription: step.printDescription,
                  value: step.selectedLabel,
                  price: step.price.total
                }
              }
            })
          }
        })
      }
    })

    return summary
  }
}
export default System
